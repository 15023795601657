<template>
  <div class="main-sider">
    <div id="sidebar" :class="{ open: isOpen }" @click.self="closeSidebar">
      <div class="sidebar">
        <ul>
          <li>
            <router-link to="/" @click="closeSidebar">Home</router-link>
          </li>
          <li>
            <router-link to="/about" @click="closeSidebar">About Us</router-link>
          </li>
          <li>
              <router-link to="/tech" @click="closeSidebar">IT Services</router-link>
            </li>
          <li>
            <a href="#" @click.prevent="toggleDropdown('services')">
              <router-link to="/services" @click="closeSidebar">Services</router-link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <!-- <i :class="['side-dropdown-icon', { rotated: dropdownState.services }]" class="fa fa-chevron-down"></i> -->
            </a>
            <!-- <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
              <ul v-if="dropdownState.services" class="side-dropdown-menu">
                <li><a href="#">Service 1</a></li>
                <li><a href="#">Service 2</a></li>
                <li><a href="#">Service 3</a></li>
              </ul>
            </transition> -->
          </li>
          <!-- <li>
            <a href="#" @click.prevent="toggleDropdown('pages')">
              Pages &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <i :class="['side-dropdown-icon', { rotated: dropdownState.pages }]" class="fa fa-chevron-down"></i>
            </a>
            <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
              <ul v-if="dropdownState.pages" class="side-dropdown-menu">
                <li><a href="#">Page 1</a></li>
                <li><a href="#">Page 2</a></li>
                <li><a href="#">Page 3</a></li>
              </ul>
            </transition>
          </li> -->
          <li>
            <router-link to="/meet" @click="closeSidebar">Meet Us</router-link>
          </li>
          <li>
            <router-link to="/contact" @click="closeSidebar">Contact Us</router-link>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropdownState: {
        services: false,
        pages: false,
      },
    };
  },
  methods: {
    closeSidebar() {
      this.$emit('close-sidebar');
    },
    toggleDropdown(section) {
      // Close all dropdowns before opening the clicked one
      for (let key in this.dropdownState) {
        if (key !== section) {
          this.dropdownState[key] = false;
        }
      }
      // Toggle the clicked dropdown
      this.dropdownState[section] = !this.dropdownState[section];
    },
    beforeEnter(el) {
      el.style.maxHeight = '0';
      el.style.opacity = '0';
    },
    enter(el) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'max-height 0.5s ease-out, opacity 0.5s ease';
      el.style.maxHeight = el.scrollHeight + 'px';
      el.style.opacity = '1';
    },
    leave(el) {
      el.style.transition = 'max-height 0.5s ease-out, opacity 0.5s ease';
      el.style.maxHeight = '0';
      el.style.opacity = '0';
    },
  },
  beforeRouteLeave(to, from, next) {
    this.closeSidebar();
    next();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
