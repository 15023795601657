import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', name: 'LandingPage', component: () => import('../pages/LandingPage.vue') },
  { path: '/services', name: 'ServicesPage', component: () => import('../pages/ServicesPage.vue') },
//   { path: '/industries', name: 'IndustryPage', component: () => import('../pages/IndustryPage.vue') },
//   { path: '/digital', name: 'DigitalPage', component: () => import('../pages/DigitalPage.vue') },
//   { path: '/insight', name: 'InsightPage', component: () => import('../pages/InsightPage.vue') },
//   { path: '/promotion', name: 'PromotionPage', component: () => import('../pages/PromotionPage.vue') },
//   { path: '/accounting', name: 'AccountingPage', component: () => import('../pages/AccountingPage.vue') },
  { path: '/about', name: 'AboutPage', component: () => import('../pages/AboutUs.vue') },
//   { path: '/visit', name: 'VisitVisa', component: () => import('../pages/VisitVisa.vue') },
  { path: '/contact', name: 'Contactus', component: () => import('../pages/ContactUs.vue') },
  { path: '/tech', name: 'IT Services', component: () => import('../pages/TechServices.vue') },
  { path: '/meet', name: 'Meetus', component: () => import('../pages/MeetUs.vue') },
  { path: '/mem1', name: 'mem1', component: () => import('../pages/PageComponent1.vue') },
  { path: '/mem2', name: 'mem2', component: () => import('../pages/PageComponent2.vue') },
  { path: '/mem3', name: 'mem3', component: () => import('../pages/PageComponent3.vue') },
  { path: '/mem4', name: 'mem4', component: () => import('../pages/PageComponent4.vue') },
  { path: '/mem5', name: 'mem5', component: () => import('../pages/PageComponent5.vue') },
  { path: '/mem6', name: 'mem6', component: () => import('../pages/PageComponent6.vue') },
  { path: '/mem7', name: 'mem7', component: () => import('../pages/PageComponent7.vue') },
  { path: '/mem8', name: 'mem8', component: () => import('../pages/PageComponent8.vue') },
  { path: '/mem9', name: 'mem9', component: () => import('../pages/PageComponent9.vue') },
  { path: '/mem10', name: 'mem10', component: () => import('../pages/PageComponent10.vue') }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
