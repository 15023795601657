<!-- src/components/UniqueTextLoader.vue -->
<template>
    <div class="loader-container" v-if="isLoading">
      <div class="text-wrapper">
        <span 
          v-for="(char, index) in text.split('')" 
          :key="index" 
          :class="{ 'space': char === ' ' }"
          :style="{ animationDelay: `${index * 0.1}s` }"
        >
          {{ char }}
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        text: 'CFO ON CALL', // Text to display in loader
      };
    },
  };
  </script>
  
  <style scoped>
  /* Full-screen loader */
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d0d0d; /* Dark background */
    z-index: 9999;
  }
  
  /* Text wrapper styling */
  .text-wrapper {
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-family: 'PlusJakartaSans-Bold', sans-serif;
    color: #00bfff; /* Bright color for text */
    font-weight: bold;
    letter-spacing: 0.1em;
  }
  
  /* Individual character styling */
  .text-wrapper span {
    display: inline-block;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeInUp 1s forwards;
  }
  
  /* Special class for spaces */
  .text-wrapper .space {
    width: 0.5em; /* Adjust the width to control the space */
  }
  
  /* Animation effect */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  