<template>
    <div ref="textContainer" class="animated-text">
      <!-- Wrap each word and animate each letter within that word -->
      <span v-for="(word, wordIndex) in words" :key="wordIndex" class="word">
        <span
          v-for="(letter, letterIndex) in word.split('')"
          :key="letterIndex"
          class="letter"
          :style="{ transitionDelay: `${getLetterDelay(wordIndex, letterIndex)}ms` }"
        >
          {{ letter }}
        </span>
        <!-- Add a space between words -->
        <span v-if="wordIndex < words.length - 1">&nbsp;</span>
      </span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      },
      triggerClass: {
        type: String,
        default: 'trigger-animation'
      }
    },
    data() {
      return {
        words: this.text.split(' ')
      };
    },
    methods: {
      getLetterDelay(wordIndex, letterIndex) {
        const totalPreviousLetters = this.words
          .slice(0, wordIndex)
          .reduce((total, word) => total + word.length, 0);
        return (totalPreviousLetters + letterIndex) * 20;
      },
      animateText() {
        const letters = this.$refs.textContainer.querySelectorAll('.letter');
        letters.forEach(letter => {
          letter.classList.add(this.triggerClass);
        });
      },
      setupIntersectionObserver() {
        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1
        };
  
        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.animateText();
              observer.unobserve(entry.target);
            }
          });
        };
  
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(this.$refs.textContainer);
      }
    },
    mounted() {
      this.setupIntersectionObserver();
    }
  };
  </script>
  
  <style scoped>
  .animated-text {
    overflow: hidden;
    display: inline-block;
    position: relative;
  }
  
  .word {
    display: inline-block;
    margin-right: 0.1em; /* Adjust space between words */
  }
  
  .letter {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .trigger-animation {
    opacity: 1;
    transform: translateY(0);
  }
  </style>
  