<template>
  <div class="main-navbar">
    <header id="navbar" :class="{ 'scrolled': isScrolled, 'hidden': !isNavVisible }">
      <div class="nav-1">
        <div class="nav-logo"></div>
        <router-link to="/" exact>
          <div class="logo">
            <img src="@/assets/images/cfo-logo.png" alt="Logo" />
          </div>
        </router-link>
      </div>
      <div class="nav-2">
        <nav class="main-nav" v-if="!isMobileView">
          <ul>
            <li>
              <router-link to="/" exact>Home</router-link>
            </li>
            <li>
              <router-link to="/about">About Us</router-link>
            </li>
            <li>
              <router-link to="/tech">IT Services</router-link>
            </li>
            <li class="dropdown">
              <router-link to="/services" class="services-link">
                Services
                <span class="dropdown-icon">
                  <!-- <i class="fa fa-chevron-down"></i> -->
                </span>
              </router-link>
              <!-- <ul class="dropdown-content">
                <li><a href="#">Service 1</a></li>
                <li><a href="#">Service 2</a></li>
                <li><a href="#">Service 3</a></li>
              </ul> -->
            </li>
            <!-- <li class="dropdown">
              <a href="#">Pages <span class="dropdown-icon"><i class="fa fa-chevron-down"></i></span></a>
              <ul class="dropdown-content">
                <li><a href="#">Page 1</a></li>
                <li><a href="#">Page 2</a></li>
                <li><a href="#">Page 3</a></li>
              </ul>
            </li> -->

            <li>
              <router-link to="/meet">Meet Us</router-link>
            </li>
            <li>
              <router-link to="/contact">Contact Us</router-link>
            </li>
          </ul>
        </nav>
        <div class="menu-icon" @click="toggleSidebar" :class="{ open: isSidebarOpen }" v-if="isMobileView">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
        <router-link to="/contact"><button class="contact-button" v-if="!isMobileView">
            GET IN TOUCH &nbsp;
            <span class="icon">➡</span>
          </button></router-link>

      </div>
    </header>
    <sidebar-component :isOpen="isSidebarOpen" @close-sidebar="toggleSidebar" />
  </div>
</template>





<script>
import SidebarComponent from './SidebarComponent.vue'; // Import Sidebar component

export default {
  components: {
    SidebarComponent,
  },
  data() {
    return {
      isSidebarOpen: false,
      isScrolled: false,
      isMobileView: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    checkViewport() {
      this.isMobileView = window.innerWidth <= 1010;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.checkViewport);
    this.checkViewport();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.checkViewport);
  },
};
</script>

<style scoped>
.main-nav ul li a {
  position: relative;
  color: #00d1d1;
  text-decoration: none;
  padding-bottom: 5px;
  transition: color 0.3s ease, border-bottom 0.3s ease, transform 0.3s ease;
}

/* Active route styling */
.router-link-active,
.router-link-exact-active {
  color: #ffffff;
  font-weight: 600;
  border-bottom: 3px solid #00d1d1;
  background: linear-gradient(120deg, rgba(0, 121, 107, 0.1), rgba(76, 175, 80, 0.1));
  border-radius: 4px;
}

/* Hover effect styling */
.main-nav ul li a:hover {
  border-bottom: 3px solid #81d4fa;
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.main-nav ul li a {
  transition: color 0.3s ease, border-bottom 0.3s ease, transform 0.3s ease, background 0.3s ease;
}
</style>