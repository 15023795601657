import { createApp } from 'vue';
import App from './App.vue';
import router from '@/assets/router/router';
import '@/assets/styles/styles.css';
import '@fortawesome/fontawesome-free/css/all.css';

// Import FontAwesomeIcon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import AnimatedText component
import AnimatedText from '@/components/AnimatedText.vue';

const app = createApp(App);

// Register FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Register AnimatedText component globally
app.component('AnimatedText', AnimatedText);

// Register global method
app.config.globalProperties.$isActive = (route) => {
  return app.config.globalProperties.$route.path === route;
};

app.use(router);
app.mount('#app');
